import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface ICategory extends IBaseAudit {
  name: string;
  name_ar?: string;
  image_path?: string;
  is_new?: boolean;
  has_departments?: boolean;
}

export interface ICategoryListData {
  id: number;
  name: string;
  image_url?: string;
  is_new?: boolean;
  has_departments?: boolean;
}

export const categorySelect = {
  ...BaseSelect
};

export const categoryListSelect = {
  ...BaseSelect,
  image_path: true,
  is_new: true,
  has_departments: true
};
