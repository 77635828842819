import { Pipe, PipeTransform } from '@angular/core';

import { IEmployeeListItem } from '@ecommerce/common-types'; // replace 'path-to-interface' with the actual path to IEmployeeListItem interface

@Pipe({ name: 'roleName' })
export class RoleNamePipe implements PipeTransform {
  transform(employee: IEmployeeListItem): string {
    if (employee.user_type === 'admin') {
      return employee?.role?.name ? employee.role.name : '';
    } else {
      return employee.operational_roles && employee.operational_roles.length > 0
        ? employee.operational_roles.map((d): string => d.role.charAt(0).toUpperCase() + d.role.slice(1)).join(', ')
        : '';
    }
  }
}
