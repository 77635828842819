import { IUser, IUserData } from '../user.interface';

export interface IValusAuthenticationReq {
  username: string;
  password: string;
}

export interface IValusCommonResponse {
  ResponseCode: string;
  ResponseMessage: string;
  IsSuccess: boolean;
}

export interface IValusGetProductsRes {
  ProductList: IValusProduct[];
}

export interface IValusGetMemberDetailsRes {
  IdTypes: { IdTypeCode: 'Mobile_Number' | 'LoyaltyID'; AccountIdValue: string }[];
  PointBalance: number;
  Response?: IValusCommonResponse;
}

export interface IGetLoyaltyRes {
  loyalty_id?: string;
  points: number;
}

export interface IValusGetUserBalanceRes {
  AccountIdValue: string;
  PointsAvailable: number;
  RequiredPoints: number;
  Response: IValusCommonResponse;
}

export interface IGetLoyaltyBalanceRes {
  loyalty_id: string;
  convertor: number;
  points: number;
}

export interface IValusGetLoyaltyTransaction {
  TransactionUniqueReference: string;
  PointsEarned: string;
  Timestamp: string;
  Value: number;
}

export interface IGetLoyaltyTransaction {
  reference: string;
  earned: string;
  timestamp: string;
  value: number;
}

export interface IValusProduct {
  Id: string;
  Sku: string;
  Name: string;
  Description: string;
  IssuePoints: boolean;
  Points: number;
  ProductFlags: [];
}

export interface IValusAuthenticationRes {
  access_token: string;
  token_type: string;
  expires_in: string;
  userName: string;
  '.issued': string;
  '.expires': string;
}

export interface IProductBulkUploadReq {
  products: IProductBulkUploadProducts[];
}

export interface IProductBulkUploadProducts {
  name: string;
  description: string;
  sku: string;
  barcode: string;
  is_excluded: boolean;
}

export interface IValusBulkUploadOrUpdateReq {
  Name: string;
  Description: string;
  Sku: string;
  Is_Excluded: boolean;
  Variables: IValusProductVariables[];
  Flags?: [];
}

export interface IValusProductVariables {
  Name: string;
  Value: string;
}

export interface IValusCreateUserReq {
  ProgramCode: string;
  CountryCode: string;
  DoB: string;
  EnrollmentLocationCode: string;
  IdTypeCode: string;
  AccountIdValue: string;
  IsActive: boolean;
  EnrollmentDate: Date;
  Variables: VariableTypesCreateUser[];
}

export interface IValusCreateVariableReq {
  ProgramCode: string;
  AccountIdValue: string;
  Variables: VariableTypesCreateUser[];
}

interface IValusCreateUserResCode {
  ResponseCode: string;
}

export interface IValusCreateUserRes {
  Response: IValusCreateUserResCode;
  MemberId: string;
  ProgramCode: string;
  CountryCode: string;
  DoB: Date;
  EnrollmentLocationCode: string;
  IdTypeCode: string;
  AccountIdValue: string;
  IsActive: boolean;
  PointBalance: number;
  EnrollmentDate: Date;
  IdTypes: IdTypes[];
}

export interface IValusCreateVariableRes {
  ReturnCode: string;
  ProgramCode: string;
  AccountIdValue: string;
  Variables: VariableTypesCreateUser[];
}

export interface ILoyaltyRedeemPointsPayload {
  order_id: number;
  user: IUserData;
  value: number;
}

export interface IValusPostRedemptionReq {
  AccountIdValue: string;
  RewardsCode: string;
  TransactionTypeCode: string;
  TransactionUniqueReference: string;
  Value: number;
  LocationCode: string;
  Timestamp: string;
  ProgramCode: string;
}

export interface IValusPostRedemptionRes {
  RedeemRewardsId: number;
  AccountIdValue: string;
  RewardsCode: string;
  ProgramCode: string;
  TransactionTypeCode: string;
  LocationCode: string;
  TransactionUniqueReference: string;
  PointsRedeemed: number;
  Value: number;
  Quantity: number;
  Timestamp: string;
  PartNumber: string;
  TypeOfGift: string;
  ChassisSize: string;
  ProductFamily: string;
  ItemsPerPack: string;
  DeliveryAddress: string | null;
  MemberDetails: string | null;
  RewardVariables: string | null;
  Status: boolean;
  Pin: number;
  ReturnCode: string;
  Response?: IValusCommonResponse;
}

export interface IValusCancelRedemptionReq {
  RedeemRewardId: number;
  TransactionUniqueRef: string;
  ProgramCode: string;
}

export interface IValusCancelRedemptionRes {
  RedeemRewardsId?: number;
  AccountIdValue?: string;
  RewardsCode?: string;
  ProgramCode?: string | null;
  TransactionTypeCode?: string;
  LocationCode?: string;
  TransactionUniqueReference?: string | null;
  PointsRedeemed?: number;
  Value?: number;
  Quantity?: number;
  Timestamp?: string;
  PartNumber?: string;
  TypeOfGift?: string;
  ChassisSize?: string;
  ProductFamily?: string;
  ItemsPerPack?: string;
  DeliveryAddress?: string | null;
  MemberDetails?: string | null;
  RewardVariables?: string | null;
  Status?: boolean;
  ReturnCode?: string;
  Response?: IValusCommonResponse;
}

export interface IGrpcIssueLoyaltyPointsReq {
  order_id: number;
  order_total: number;
  user: IUserData;
  valus_transaction_products: IGrpcValusTransactionProduct[];
  is_adjustment?: boolean;
}

export interface IGrpcValusTransactionProduct {
  sku: string;
  product_itm_qty: number;
  total_value: number;
}

export interface IGrpcValusCancelRedemptionReq {
  redeem_reward_id: number;
  transaction_unique_ref: string;
  order_id: number;
  user_id: number;
}

interface VariableTypesCreateUser {
  Name: string;
  VariableValue: string;
}

interface IdTypes {
  IdTypeCode: string;
  AccountIdValue: string;
}

export interface IGrpcLoyaltyRegisterUserReq {
  user: IUser;
}

export interface IValusTransactionProduct {
  Sku: string;
  ProductItmQty: number;
  TotalValue: number;
  Timestamp: string;
}

export interface IValusPostTransactionsReq {
  AccountIdValue: string;
  ProgramCode: string;
  LocationCode: string;
  TransactionTypeCode: string;
  TransactionUniqueReference: string;
  Value: number;
  Timestamp: string;
  TransactionProductList: IValusTransactionProduct[];
}

export interface IValusPostTransactionsRes {
  TransactionId: number;
  MemberId: number;
  AccountIdValue: string;
  ProgramCode: string;
  LocationCode: string;
  TransactionTypeCode: string;
  TransactionTypeName: string;
  TransactionUniqueReference: string;
  Value: number;
  BasePoints: number;
  BonusPoints: number;
  PointsEarned: number;
  Timestamp: string;
  ReturnCode: string;
  TransactionProductList: IValusTransactionProduct[];
  Response?: IValusCommonResponse;
}

export const valusResponseCode = {
  SUCCESS_CODE_200: 200,
  ERROR_CODE_400: 400,
  CREATE_USER_ERROR_1101: '1101'
};

export enum ContentTypeEnum {
  APP_JSON = 'application/json',
  FORM_URL_ENCODED = 'application/x-www-form-urlencoded'
}

export enum ProductVariablesEnum {
  NAME = 'SKU'
}

export enum ValusActionEnum {
  AUTH = 'token',
  UPLOAD_UPDATE_PRODUCTS = 'productsBulkUpload',
  GET_PRODUCTS = 'products',
  MEMBERS = 'members',
  TRANSACTIONS = 'transactions',
  VARIABLE_ADD = 'variableAdd',
  REDEEM_REWARD = 'redeemRewards',
  CANCEL_REDEMPTION = 'cancelReward',
  REDEEM_VALUE = 'redeemValue'
}
