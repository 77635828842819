import {} from 'googlemaps';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { timer, Subscription, switchMap, of, Observable, tap, catchError } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { RootService } from './common/service/root/root.service';
import { AuthService } from './common/service';

import { defineAbilities } from './common/auth/ability.factory';

import { AppAbility, constants, IResponse, ItoastListener } from '@ecommerce/common-types';

@Component({
  selector: 'ecomm-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Danube';
  public isError = false;
  public message = '';
  public showRefreshModal = false;
  private showToaster = false;
  private authenticateSubscription?: Subscription;
  private noCheckUrls = ['/', '/forgot-password', '/login', '/unauthorize'];
  private currentRouterUrl = '';

  constructor(
    private appAbility: AppAbility,
    private rootService: RootService,
    private authService: AuthService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private router: Router
  ) {
    this.appAbility.update(defineAbilities());
    this.router.events.subscribe((val): void => {
      if (val instanceof NavigationEnd) {
        if (!this.currentRouterUrl && !this.noCheckUrls.includes(this.router.url)) this.validateToken().subscribe();
        this.currentRouterUrl = this.router.url;
      }
    });
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.rootService.toastListener().subscribe((data: ItoastListener): void => {
      this.showToaster = (data && data.show) ?? false;

      if (this.showToaster) {
        this.isError = data.is_error;
        this.messageService.add({
          severity: data.is_error ? 'error' : 'success',
          summary: data.is_error ? 'Error' : 'Success',
          detail: data.message
        });
      }
    });

    this.checkAthentication();
  }

  public ngOnDestroy(): void {
    this.authenticateSubscription?.unsubscribe();
  }

  public onClose(): void {
    this.messageService.clear('c');
  }

  public refreshPage(): void {
    location.reload();
  }

  private checkAthentication(): void {
    this.authenticateSubscription?.unsubscribe();
    this.authenticateSubscription = timer(0, constants.RE_AUTHENTICATION_FREQUENCY)
      .pipe(
        switchMap(() => {
          if (!this.noCheckUrls.includes(this.currentRouterUrl || this.router.url)) return this.validateToken();
          return of(true);
        })
      )
      .subscribe();
  }

  private validateToken(): Observable<boolean | IResponse<string>> {
    return this.authService.validateToken().pipe(
      tap((response: IResponse<string>): void => {
        const token: string | null = localStorage.getItem('x-auth-token');
        if (token && token !== response.data) {
          localStorage.setItem('x-auth-token', response.data);
          this.appAbility.update(defineAbilities());
          this.showRefreshModal = true;
        }
      }),
      catchError((err): Observable<boolean> => {
        if (err?.code === 403) {
          this.authenticateSubscription?.unsubscribe();
          this.rootService.logOut();
        }

        return of(true);
      })
    );
  }
}
