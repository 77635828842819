<router-outlet></router-outlet>

<p-toast (onClose)="onClose()">
  <ng-template let-message pTemplate="message">
    <img src="../assets/images/check-toaster.svg" width="31px" class="m-r-15" alt="Check" *ngIf="!isError" />
    <img src="../assets/images/waring-toaster.svg" width="31px" class="m-r-15" alt="Check" *ngIf="isError" />
    <span>{{ message.detail }}</span>
  </ng-template>
</p-toast>

<p-dialog
  styleClass="confirm-modal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [(visible)]="showRefreshModal"
>
  <ng-template pTemplate="header">
    <h4 class="modal-title">Message</h4>
  </ng-template>

  <div class="modal-body text-center">
    <h3 class="info-message">
      The permissions are changed against this user, the page will be refreshed to reflect new changes
    </h3>
  </div>

  <ng-template pTemplate="footer">
    <div class="grid">
      <div class="col-12 text-center m-t-15 m-b-20">
        <button type="button" class="btn btn-create modal-btn" type="submit" (click)="refreshPage()">Ok</button>
      </div>
    </div>
  </ng-template>
</p-dialog>
