import * as Sentry from '@sentry/angular-ivy';
import { BrowserOptions } from '@sentry/angular-ivy';

import { environment } from './environments/environment';

const ignoreErrors = ['ExpressionChangedAfterItHasBeenCheckedError'];
const sentryConfig: BrowserOptions = {
  ...environment.sentryConfig,
  dsn: 'https://19bca42e7d4de5ca9ce954181862921a@o4506024203714560.ingest.sentry.io/4506030306623488',
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i
  ],
  ignoreTransactions: ignoreErrors,
  ignoreErrors: ignoreErrors
};

export function initSentry() {
  Sentry.init(sentryConfig);
}
