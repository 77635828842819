import { IBaseAudit } from '../auditable.interface';
import { ICommonOption, BaseSelect } from '../common.interface';
import { BinCategorySelect, IBinCategory } from '../product/bin-category.interface';
import { IProduct } from '../product/product.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IOrderShipment } from './order-shipment.interface';
import { IProductVariant } from '../product/product-variant.interface';
import { IWmsProductReport } from '../warehouse-management/wms-product-report.interface';
import { IEmployeeData } from '../employee.interface';
import { IOrderPickingShipmentBinProducts } from './order-picking-stagger.interface';

export interface IOrderProduct extends IBaseAudit {
  order_shipment_id: number;
  product_id: number;
  category_id: number;
  quantity: number;
  replacement_pref?: OrderProductReplacementPrefEnum;
  price: number;
  action_price: number;
  sale_price?: number;
  status: OrderProductStatusEnum;
  picker_id?: number;
  product_variant_id?: number;
  replacement_of?: number;
  replaced_by?: number;
  picked_quantity?: number;
  picked_weight?: number;
  returned_quantity?: number;
  delivery_instruction?: string;
  product_report_id?: number;
  loyalty_point?: number;
  created_at?: Date;
  updated_at?: Date;
  order_shipment?: IOrderShipment;
  product?: Partial<IProduct>;
  order_replacement_products?: IOrderReplacementProduct[];
  supermarket?: Partial<ISuperMarket>;
  added_by?: number;
  modified_by?: number;
  meta?: Record<string, unknown>;
  product_variant?: IProductVariant;
  product_report?: IWmsProductReport;
  replaced_order_product?: IOrderProduct;
  replacement_order_product?: IOrderProduct;
  picker?: IEmployeeData;
  replacement?: Partial<IProduct>;
  order_picking_shipment_bin_products?: IOrderPickingShipmentBinProducts;
}

export interface IOrderReplacementProduct {
  order_product_id: number;
  product_id: number;
  order_product?: IOrderProduct;
  product?: IProduct;
}

export interface IOrderItemData {
  supermarket_id: number;
  product_id: number;
  product_variant_id: number;
  quantity: number;
  sku?: string;
}

export interface IGrpcOrderDetailProduct extends IProduct {
  binCategory?: IBinCategory;
}

export interface IGrpcGetOrderProductResponse {
  products: IGrpcOrderDetailProduct[];
  variants: IProductVariant[];
}

export interface IGrpcGetCustomerOrderProductRes {
  products: IProduct[];
  variants: IProductVariant[];
}

export interface IProductsPurchaseCount {
  product_id: number;
  total_quantity: number;
}

export interface IGrpcGetOrderedProductLastWeekPurchaseRes {
  products: IProductsPurchaseCount[];
}

export enum OrderProductStatusEnum {
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  PICKED = 'picked',
  REPLACED = 'replaced',
  ISSUE = 'issue'
}

export enum OrderProductReplacementPrefEnum {
  CANCEL = 'cancel',
  REPLACE_WITH_SIMILAR_PRODUCT = 'replace-with-similar-product',
  REPLACE_WITH_SELECTED_PRODUCTS = 'replace-with-selected-products'
}

export const orderProductReplacementPrefs: ICommonOption<OrderProductReplacementPrefEnum>[] = [
  {
    id: OrderProductReplacementPrefEnum.CANCEL,
    name: 'Cancel'
  },
  {
    id: OrderProductReplacementPrefEnum.REPLACE_WITH_SIMILAR_PRODUCT,
    name: 'Replace with Similar'
  },
  {
    id: OrderProductReplacementPrefEnum.REPLACE_WITH_SELECTED_PRODUCTS,
    name: 'Replace with Selected Items'
  }
];

export const orderProductStatusOptions: ICommonOption<OrderProductStatusEnum>[] = [
  {
    id: OrderProductStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: OrderProductStatusEnum.ASSIGNED,
    name: 'Assigned'
  },
  {
    id: OrderProductStatusEnum.PICKED,
    name: 'Picked'
  },
  {
    id: OrderProductStatusEnum.REPLACED,
    name: 'Replaced'
  },
  {
    id: OrderProductStatusEnum.ISSUE,
    name: 'Issue'
  }
];

export enum ModifyOrderEnum {
  ADDED = 'added',
  UPDATED = 'updated',
  DELETED = 'deleted'
}

export const customerOrderDetailProductSelect = {
  ...BaseSelect,
  name_ar: true,
  weight: true,
  weight_increment: true,
  default_weight_count: true,
  max_weight_per_order: true,
  sku: true,
  images: {
    id: true,
    file_name: true
  },
  barcodes: { barcode: true }
};

export const viewPurchaseOrderProductSelect = {
  ...BaseSelect,
  sku: true,
  images: {
    id: true,
    file_name: true
  }
};

export const orderDetailProductSelect = {
  ...customerOrderDetailProductSelect,
  sku: true,
  weight: true,
  weight_increment: true,
  default_weight_count: true,
  max_weight_per_order: true,
  moq_limit: true,
  loyalty_point: true,
  category: {
    id: true,
    name: true
  },
  bin_category: { ...BinCategorySelect },
  barcodes: {
    barcode: true
  }
};

export const orderDetailProductVariantSelect = {
  id: true,
  option_value_id: true,
  option_type: { presentation_en: true },
  option_value: {
    presentation_en: true,
    presentation_ar: true
  },
  images: { url: true }
};
