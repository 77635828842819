import { IKeyMapping } from '../key-mapping.interface';
import { IOptionType, IOptionValue } from './option-type.interface';
import { IProductVariantImage } from './product-variant.interface';

export interface IAlgoliaProduct {
  country_id: number;
  category_id: number;
  name_en: string;
  name_ar: string;
  slug: string;
  image: string;
  weight: number;
  validity_tag_en: string;
  validity_tag_ar: string;
  loyalty_point: number;
  perform_liquid_weight_check: boolean;
  weight_increment: number;
  default_weight_count: number;
  master_id: number;
  default_taxon_id: number;
  brand_en: string;
  brand_ar: string;
  objectID: number;
  category_name_en: string;
  category_name_ar: string;
  max_grams_per_order?: number;
  available_stores?: number[];
  is_gluten_free?: boolean;
  inventory_modifiers?: IKeyMapping<IAlgoliaInventoryModifiers>;
  variants?: IAlgoliaProductVariant[];
  taxon_en?: IKeyMapping<string[]>;
  taxon_ar?: IKeyMapping<string[]>;
  taxon_ids?: IKeyMapping<number[]>;
  moq_limit?: number;
  deactivate?: boolean;
  cod_enabled?: boolean;
  purchases_last_7_days?: number;
  purchases_last_7_days_updated_at?: Date;
}

export interface IAlgoliaProductVariant {
  id: number;
  option_type: IOptionType;
  option_value: IOptionValue;
  image?: IProductVariantImage;
}

export interface IAlgoliaInventoryModifiers {
  product_id?: number;
  supermarket_id?: number;
  action_price?: number;
  sale_price?: number;
  sale_start_date?: string;
  sale_end_date?: string;
  sale_start_time?: string;
  sale_end_time?: string;
  availability_start_date?: string;
  availability_end_date?: string;
  availability_start_time?: string;
  availability_end_time?: string;
  in_stock?: boolean;
  stock_quantity?: number;
  reserved_quantity?: number;
}
