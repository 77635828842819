export default {
  CRYPTO_RANDOM_BYTES: 16,
  DEFAULT_IMAGE_HEIGTH: 40,
  MOBILE_LIST_PAGE_LIMIT: 10,
  ORDERS_PAGE_LIMIT: 20,
  BASE_LIST_LIMIT: 20,
  PAGE_LIMIT: 25,
  PAGINATION_LIMIT: 10,
  PRODUCTS_PAGE_LIMIT: 8,
  REDIS_CACHE_EXPIRE: 60,
  FORGOT_PASSWORD_OTP_EXPIRE_TIME: 3600000, // milliseconds -> 1 hour
  MOBILE_OTP_EXPIRE_TIME: 1800000, // milliseconds -> 30 minutes
  RE_AUTHENTICATION_FREQUENCY: 300000,
  SALT_DATA: 10,
  PASSWORD_HASH_ITERATION: 20,
  TAXONS_PRODUCTS_PAGE_LIMIT: 5,
  DATE_FORMAT: 'YYYY-MM-DD',
  DAY_FORMAT: 'dddd',
  TIME_FORMAT: 'HH:mm',
  ORDINAL_SUFFIX_DATE_TIME_FORMAT: 'MMMM Do, YYYY HH:mm',
  FULL_TIME_FORMAT: 'HH:mm:ss',
  TIME_12_HOURS_FORMAT: 'hh:mm A',
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  DATE_TIME_12_HOURS_FORMAT: 'DD/MM/YYYY hh:mm A',
  DATE_TIME_FULL_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  UTC_DATE_TIME_FULL_FORMAT: 'DD/MM/YYYY HH:mm:ss ZZ',
  DB_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss.SSS',
  FILTER_DATE_FORMAT: 'YYYY/MM/DD',
  FILTER_DATE_TIME_FORMAT: 'YYYY/MM/DD HH:mm',
  INVOICE_DATE_FORMAT: 'YYYY-DD-MM',
  MAIL_DATE_TIME_FORMAT: 'Do MMMM YYYY',
  GRID_DATE_FORMAT: 'DD/MM/YYYY',
  CUSTOM_DATE_FORMAT: 'HH:mm D MMM',
  NO_RECORD_FOUND: 'No record found',
  PRODUCT_SLUG_BASE_ROUTE: 'en/deeplink/show_product',
  THANKS_AND_REGARDS: 'eCommerce 2.0 Team',
  BARCODE_TYPE: 'code128',
  QRCODE_TYPE: 'qrcode',
  FACEBOOK_GRAPH_API_URL: 'https://graph.facebook.com/debug_token?input_token={inputToken}&access_token={accessToken}',
  GOOGLE_AUTH_API_URL: 'https://www.googleapis.com/oauth2/v3/tokeninfo?access_token={accessToken}',
  SPIKE_ADS_API_URL:
    'https://api-spikecom-stagingv2.azurewebsites.net/api/adserve/serve/?type=json&key={key}{other_params}',
  GOOGLE_MAPS_COMPUTE_ROUTE_URL: 'https://routes.googleapis.com/directions/v2:computeRoutes',
  MAX_LIQUID_WEIGHT: 30 * 1000, // 30kg in grams
  REDIS_APP_CONFIG_DATA_KEY: 'app-config-data',
  CUSTOMER_DELIVERY_SLOTS_DAYS_LIMIT: 9,
  PAYMENT: {
    MADA_CARD_SCHEME_TYPE: 'mada',
    REDIS_CACHE_ORDER_EXPIRE: 1800, // 1800 seconds -> 30 minutes,
    FULL_AMOUNT_CURRENCIES: [
      'BIF',
      'CLF',
      'DJF',
      'GNF',
      'ISK',
      'JPY',
      'KMF',
      'KRW',
      'PYG',
      'RWF',
      'UGX',
      'VUV',
      'VND',
      'XAF',
      'XOF',
      'XPF'
    ],
    AMOUNT_DIVIDED_BY_1000_CURRENCIES: ['BHD', 'IQD', 'JOD', 'KWD', 'LYD', 'OMR', 'TND'],
    AMOUNT_DIVIDED_BY_100_CLP_CURRENCY: 'CLP'
  },
  CEQUENS_API_URL: 'https://apis.cequens.com/sms/v1/messages',
  PACKER_STAGGER_JOB_ITEM_LIMIT: 20,
  PHILOSOPHIC_API_BASE_URL: 'https://api-dot-smash-eutest.appspot.com/external/api'
};
