import { IAuditable } from './auditable.interface';
import { ICheckoutCard } from './checkout.interface';
import { IEmployeeOperationalRole, OperationalEmployeeRoleEnum } from './employee.interface';
import { ILanguage } from './language.interface';
import { IAddress } from './location/address.interface';
import { ICity } from './location/city.interface';
import { ICountry } from './location/country.interface';
import { IGetLoyaltyRes } from './loyalty-management/loyalty-management-system.interface';
import { ICommonEmailData, INotificationCommonEmailData } from './notification.interface';
import { IOrderDetailCommonEmailContent } from './order/order.interface';
import { IPermissionToken } from './permission.interface';
import { ISuperMarket } from './supermarket/super-market.interface';
import { ITimeSlot } from './supermarket/time-slot.interface';

export interface IAuthUser extends IAuditable {
  user: number;
  email?: string;
  is_email_verified?: boolean;
  email_verification_key?: string;
  spree_api_key?: string;
  otp?: number;
  otp_generated_at?: number;
  password?: string;
  password_salt?: string;
  password_updated_at?: Date;
  login_type?: string;
  social_id?: string;
  mobile_otp?: number;
  mobile_otp_generated_at?: number;
  mobile_verified?: boolean;
  is_blacklisted?: boolean;
}

export const userSelect = {
  id: true,
  is_deleted: true,
  user: true,
  email: true,
  password: true,
  password_salt: true,
  otp: true,
  otp_generated_at: true,
  mobile_otp: true,
  mobile_otp_generated_at: true,
  mobile_verified: true,
  is_blacklisted: true,
  spree_api_key: true
};

export const referralDetailSelect = {
  id: true,
  balance: true,
  referral_code: true
};

export interface IUserVerifyOtp {
  otp_time_expired: boolean;
  valid_otp: boolean;
}

export interface ILoginResponse {
  id?: number;
  email: string;
  first_name: string;
  last_name: string;
  country_id?: number;
  country_code: string;
  mobile_number: string;
  gender?: UserGenderEnum;
  dob?: string;
  user_id: number;
  token?: string;
  refresh_token?: string;
  language_id: number;
  is_mobile_verified?: boolean;
  referral_code?: string;
  city?: {
    id: number;
    name: string;
    latitude?: number;
    longitude?: number;
    country: {
      id: number;
      name: string;
    };
  };
  status?: boolean;
  created_at?: string;
}

export interface IUser extends IAuditable {
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  gender?: UserGenderEnum;
  country_code?: string;
  mobile_number?: string;
  dob?: Date;
  referral_code?: string;
  language_id?: number;
  language?: ILanguage;
  role_id?: number;
  roles?: OperationalEmployeeRoleEnum[];
  operational_roles?: IEmployeeOperationalRole[];
  super_market_id?: number;
  super_market_ids?: number[];
  latitude?: number;
  longitude?: number;
  country_id?: number;
  city_id?: number;
  country?: ICountry;
  city?: ICity;
  user_type?: UserTypeEnum;
  active?: boolean;
  receive_marketing_promotions?: boolean;
  supermarkets?: ISuperMarket[];
  addresses?: IAddress[];
  token?: string;
  login_type?: LoginTypeEnum;
  access_token?: string;
  social_id?: string;
  isNewCustomer?: boolean;
  checkout_cards?: ICheckoutCard[];
  mobile_verified?: boolean;
  is_blacklisted?: boolean;
  blacklisted_at?: Date;
  blacklisted_reason?: string;
  id?: number;
  loyalty_point?: IGetLoyaltyRes;
}

export interface IAddUser extends IUser {
  token: string;
  email_verification_key: string;
}

export interface ICustomerEmailVerificationData {
  email: string;
}

export interface ICustomerWelcomeVerificationEmail {
  verification_link: string;
}

export interface ICustomerWelcomeVerificationEmailContent
  extends INotificationCommonEmailData,
    IOrderDetailCommonEmailContent {
  header_1: string;
  header_2: string;
  content_1: string;
  content_2: string;
  verify_email_button: string;
}

export interface ICustomerVerifiedEmailContent extends INotificationCommonEmailData {
  header_1: string;
  header_2: string;
  content_1: string;
  content_2: string;
}

export interface ICustomerEmailVerificationView {
  api_base_url: string;
  success_message: string;
  error_message: string;
}

export interface IGrpcUserNameReq {
  name: string;
}

export interface IGrpcUserIdReq {
  id: number;
}

export interface IGrpcUserBlacklistReq {
  id: number;
  is_blacklisted: boolean;
}

export interface IGrpcUser {
  user: IUser;
}

export interface IGrpcUsers {
  users: IUser[];
}

export enum UserGenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHERS = 'others'
}

export enum LoginTypeEnum {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple'
}

export interface IUpdateUser {
  data: number;
}

export interface IUserToken {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  country_id: number;
  country_code?: string;
  mobile_number?: string;
  dob: Date;
  super_market_id: number;
  super_market_ids?: number[];
  roles: OperationalEmployeeRoleEnum[];
  role_id: number;
  role_updated_at: number;
  password_updated_at?: number;
  user_type?: string;
  permissions: IPermissionToken[] | string[][];
  status?: boolean;
  login_type?: string;
  social_id?: string;
}

export interface IUserData {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  country_id?: number;
  country_code?: string;
  mobile_number?: string;
  gender?: UserGenderEnum;
  dob?: Date;
  otp?: number;
  otp_generated_at?: number;
  password?: string;
  super_market_id?: number;
  super_market_ids?: number[];
  referral_code?: string;
  language_id?: number;
  is_deleted?: boolean;
  latitude?: number;
  longitude?: number;
  country?: ICountry;
  city?: ICity;
  roles?: OperationalEmployeeRoleEnum[];
  role_id?: number;
  role_updated_at?: number;
  user_type?: string;
  permissions?: IPermissionToken[] | string[][];
  status?: boolean;
  login_type?: string;
  is_blacklisted?: boolean;
  active?: boolean;
  created_at?: Date;
}

export interface IUserChangePassword {
  password: string;
  new_password: string;
  confirm_password: string;
}

export interface IUserLogin {
  email: string;
  password: string;
}

export interface IUserForgotPassword {
  email: string;
}

export interface IGrpcCreateUserReq extends IUserToken {
  platform: UserTypeEnum;
  encrypted_password: string;
}

export interface IGrpcCreateUserRes {
  token: string;
  email_verification_key: string;
}

export interface IGrpcUpdateUserMobileNumberReq {
  user_id: number;
  country_code: string;
  mobile_number: string;
}

export interface IGrpcUpdateUserMobileNumberRes {
  status: boolean;
}

export interface IGrpcCheckMobileNumberReq {
  country_code: string;
  mobile_number: string;
}

export interface IGrpcCheckMobileNumberRes {
  exist: boolean;
}
export interface IGrpcGetUserReq {
  user_id: number;
  platform: UserTypeEnum;
}

export interface IGrpcGetUserRes {
  user: IUserData;
}

export interface IGrpcUpdateAuthUserReq {
  user: number;
  is_deleted?: boolean;
  mobile_verified?: boolean;
  encrypted_password?: string;
  platform?: UserTypeEnum;
}

export interface IGrpcUpdateAuthUserRes {
  updated: number;
}

export interface IForgotPasswordEmail extends ICommonEmailData {
  new_password: string;
}

export interface ICustomerForgotPasswordEmail extends ICommonEmailData {
  otp_code: string;
}

export interface IGrpcGetTimeSlotReq {
  time_slot_ids: number[];
}

export interface IGrpcGetTimeSlotRes {
  time_slots: ITimeSlot[];
}

export interface IGrpcGetAuthUserDetailReq {
  id?: number;
  email?: string;
}

export interface IGrpcGetAuthUserDetailRes {
  authUser: IAuthUser;
}

export enum UserTypeEnum {
  ADMIN = 'admin',
  OPERATION = 'operational',
  CUSTOMER = 'customer'
}

export enum UserTypeDisplayEnum {
  ADMIN = 'Administrator',
  OPERATIONAL = 'Operational Employee',
  CUSTOMER = 'Customer'
}

export type ValidateMobileNumberResult = {
  isValid: boolean;
  error?: string;
};
